<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Sharing Fee
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Pendapatan Minimum <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Pendapatan Minimum"
                      rules="required|min_value:1"
                    >
                      <money
                        v-model="minimum_income"
                        v-bind="money"
                        class="form-control"
                        placeholder="Masukkan pendapatan minimum"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] ? 'Nilai Pendapatan Minimum tidak boleh 0' : ''
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Jenis Sharing Fee <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Jenis Sharing Fee"
                      rules="required"
                    >
                      <v-select
                        v-model="sharing_fee_type"
                        label="label"
                        :options="sharing_fee_type_option"
                        :reduce="option => option.value"
                        placeholder="Pilih Jenis Sharing Fee"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nilai Sharing Fee <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nilai Sharing Fee"
                      :rules="`required|integer${
                        sharing_fee_type === 'percentage' ? '|max_value:100' : '|min_value:1'
                      }`"
                    >
                      <money
                        v-if="sharing_fee_type === 'rp'"
                        v-model="sharing_fee_value"
                        v-bind="money"
                        class="form-control"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <b-form-input
                        v-else
                        v-model="sharing_fee_value"
                        placeholder="Masukkan Nilai Sharing Fee"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nilai Maksimal Sharing Fee <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nilai Maksimal Sharing Fee"
                      rules="required|integer|min_value:1"
                    >
                      <money
                        v-model="max_nominal_sharing_fee"
                        v-bind="money"
                        class="form-control"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <small class="text-danger">{{ errors[0] ? 'Nilai Maksimal Sharing Fee tidak boleh 0' : '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            v-b-modal.modalConfirmation
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalConfirmation />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
  integer,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import { alertError, alertSuccess } from '@toast'
import { Money } from 'v-money'
import ModalConfirmation from './ModalConfirmation.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
    Money,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      alertError,
      alertSuccess,

      sharing_fee_type_option: [
        {
          value: 'percentage',
          label: 'Presentase %',
        },
        {
          value: 'rp',
          label: 'Nominal Rp',
        },
      ],
      minimum_income: '',
      sharing_fee_type: '',
      sharing_fee_value: '',
      max_nominal_sharing_fee: '',
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
        placeholder: 'Masukkan',
      },
    }
  },
  mounted() {
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        minimum_income: this.minimum_income,
        sharing_fee_type: this.sharing_fee_type,
        sharing_fee_value: parseInt(this.sharing_fee_value, 10),
        max_nominal_sharing_fee: this.max_nominal_sharing_fee,
      }

      const url = 'v1/sharing_fees/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push('/sharing-fees')
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
          this.loadingSubmit = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
